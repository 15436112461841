(function ($, Drupal) {
  Drupal.behaviors.ClinicalRealityBlockV1 = {
    getButtons: function ($module, index) {
      var $triggers = $('.js-hero-link--' + index, $module);

      if ($triggers.length) {
        $triggers = $triggers.find('a');
      } else {
        $triggers = $('.js-elc-button', $module);
        if ($triggers.length) {
          $triggers = $triggers.eq(index - 1);
        }
      }
      $triggers.removeClass('js-elc-button');

      return $triggers;
    },

    getImage: function ($module) {
      return $('.mantle-media-asset', $module);
    },

    attach: function (context, settings) {
      var self = this;
      var $modules = $('.js-clinical-reality-block-v1', context);

      $modules.each(function () {
        self.init($(this));
      });
    },

    init: function ($module) {
      var self = this;
      var clinicalRealityTrigger = $module.data().clinicalRealityTrigger || false;
      var index = $module.data().buttonIndex || 0;
      var linkImage = $module.data().linkImage;
      var mobileFullScreen = $module.data('mobileFullScreen');
      var $image = $();
      var $elements = $();

      if (linkImage) {
        $image = self.getImage($module);
        $image.each(function () {
          self.setup($module, $(this), clinicalRealityTrigger, mobileFullScreen);
        });
      }
      $elements = self.getButtons($module, index);
      $elements.each(function () {
        self.setup($module, $(this), clinicalRealityTrigger, mobileFullScreen);
      });
    },

    setup: function ($module, $elem, clinicalRealityTrigger, mobileFullScreen) {
      if (!clinicalRealityTrigger || !$elem && $elem.length || $elem.hasClass('clinical-reality-initialized')) {
        return false;
      }
      var randomId = 'clinical-reality-trigger-' + Math.random().toString(36).substr(2, 9);

      if (!$elem.is('a')) {
        $elem.addClass('clickable');
      }
      $elem.attr({
        'id': randomId,
        'role': 'button',
        'tabindex': 0,
        'href': 'javascript:void(0);'
      });
      $elem.addClass('clinical-reality-initialized');
      $elem.once().on('click', function (e) {
        e.preventDefault();
        window.location.hash = '#' + clinicalRealityTrigger;
        $module.addClass('hidden');
        if (!!mobileFullScreen) {
          $('body').addClass('clinical-reality-block--mobile-full-screen');
        }
      });
    }

  };
})(jQuery, Drupal);
